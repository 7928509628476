var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-body-2 primary mt-3 mx-3 pa-3 v-card v-card--link v-sheet theme--dark elevation-20 relative",
    staticStyle: {
      "min-width": "200px",
      "max-width": "300px"
    },
    style: {
      'pointer-events': _vm.persistent ? 'none' : 'auto'
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('input', false);
      }
    }
  }, [_vm._t("default"), !_vm.persistent ? _c('v-btn', {
    staticClass: "primary elevation-6",
    staticStyle: {
      "top": "-8px",
      "right": "-8px"
    },
    attrs: {
      "dark": "",
      "icon": "",
      "absolute": "",
      "x-small": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('input', false);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "x-small": ""
    },
    domProps: {
      "innerHTML": _vm._s(_vm.mdiClose)
    }
  })], 1) : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }