var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(_vm.$vuetify.breakpoint.xsOnly ? _vm.DialogScrollLock : _vm.MenuScrollLock, _vm._b({
    tag: "component",
    attrs: {
      "eager": "",
      "content-class": "nav-menu-content prominent"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_vm.header ? _c('v-btn', _vm._g(_vm._b({
          directives: [{
            name: "blur",
            rawName: "v-blur"
          }],
          staticClass: "header-menu font-weight-bold my-3 mx-1 px-2",
          staticStyle: {
            "border-radius": "4px"
          },
          attrs: {
            "text": "",
            "color": "ec-dark-blue",
            "disabled": _vm.disabled
          },
          on: {
            "click": function ($event) {
              _vm.click = !_vm.click;
            }
          }
        }, 'v-btn', attrs, false), on), [_vm._v(" " + _vm._s(_vm.name) + " "), _c('v-icon', {
          staticClass: "mr-n1",
          domProps: {
            "innerHTML": _vm._s(_vm.mdiMenuDown)
          }
        })], 1) : _c('v-list-item', _vm._g(_vm._b({
          directives: [{
            name: "blur",
            rawName: "v-blur"
          }],
          class: (_vm.disabled ? 'grey' : 'ec-dark-blue') + '--text',
          attrs: {
            "disabled": _vm.disabled
          },
          on: {
            "click": function ($event) {
              _vm.click = !_vm.click;
            }
          }
        }, 'v-list-item', attrs, false), on), [_c('v-icon', {
          staticClass: "ml-n2 hidden-xs-only",
          attrs: {
            "color": "ec-dark-blue"
          },
          domProps: {
            "innerHTML": _vm._s(_vm.mdiMenuLeft)
          }
        }), _c('v-list-item-title', {
          staticClass: "text-body-2"
        }, [_vm._v(" " + _vm._s(_vm.name)), _c('span', {
          staticClass: "hidden-sm-and-up"
        }, [_vm._v("…")])])], 1)];
      }
    }]),
    model: {
      value: _vm.value,
      callback: function ($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  }, 'component', _vm.$vuetify.breakpoint.xsOnly ? {} : {
    'max-width': '100vw',
    'open-on-hover': !_vm.click && !_vm.persistent,
    'close-on-click': !_vm.persistent,
    'close-on-content-click': !_vm.persistent,
    bottom: _vm.header,
    'offset-y': _vm.header,
    left: !_vm.header,
    'offset-x': !_vm.header,
    'nudge-right': _vm.header ? 0 : 1
  }, false), [_c('div', {
    staticClass: "scroll-container overflow-auto",
    class: {
      header: _vm.header,
      fill: _vm.fillWidth
    }
  }, [_vm.$vuetify.breakpoint.xsOnly ? [_c('div', {
    staticClass: "pa-3 d-flex align-center font-weight-bold"
  }, [_c('div', {
    staticClass: "flex-grow-1 grey--text text--darken-2"
  }, [_vm._v(_vm._s(_vm.name))]), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "!click": function ($event) {
        $event.stopPropagation();
        $event.preventDefault();
        _vm.value = false;
      }
    }
  }, [_c('v-icon', {
    domProps: {
      "innerHTML": _vm._s(_vm.mdiClose)
    }
  })], 1)], 1), _c('v-divider')] : _vm._e(), _vm._t("default")], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }