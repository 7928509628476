var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "btn-container relative d-flex justify-center align-center",
    on: {
      "drop": function ($event) {
        $event.stopPropagation();
        $event.preventDefault();
        return _vm.onDrop.apply(null, arguments);
      }
    }
  }, [_vm.mode == 'purchase' || _vm.mode == 'edit' ? [_c('error-dialog', {
    attrs: {
      "status": _vm.onDropStatus
    }
  }), _c('snackbar', {
    attrs: {
      "persistent": ""
    },
    model: {
      value: _vm.onDropStatus.pending,
      callback: function ($$v) {
        _vm.$set(_vm.onDropStatus, "pending", $$v);
      },
      expression: "onDropStatus.pending"
    }
  }, [_c('v-progress-circular', {
    staticClass: "mr-2",
    attrs: {
      "indeterminate": ""
    }
  }), _c('span', [_vm._v("Processing file, please wait…")])], 1), _vm.isDraggingOnPage && _vm.isPageActive && _vm.selected.type == _vm.blob.type ? _c('div', {
    staticClass: "absolute-fill d-flex justify-center align-center drop-zone"
  }, [_c('v-btn', {
    staticStyle: {
      "transform-origin": "center"
    },
    style: {
      transform: `scale(${1 / _vm.scale}) rotate(${-_vm.blob.mask.r}deg)`
    },
    attrs: {
      "icon": "",
      "x-large": "",
      "color": "white"
    }
  }, [_c('v-icon', {
    attrs: {
      "x-large": ""
    },
    domProps: {
      "innerHTML": _vm._s(_vm.mdiUpload)
    }
  })], 1)], 1) : _vm._e()] : _vm._e(), _vm.showCtrls ? _c('div', {
    staticClass: "btn text-center",
    class: {
      'is-hidden': _vm.isHidden
    },
    style: {
      transform: `scale(${1 / _vm.scale}) rotate(${-_vm.blob.mask.r}deg)`
    }
  }, [_vm.blob.type == 'VIDEO' ? _c('v-menu', {
    attrs: {
      "offset-x": "",
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on;
        return [_c('v-btn', _vm._g({
          directives: [{
            name: "blur",
            rawName: "v-blur"
          }],
          attrs: {
            "fab": "",
            "color": "primary",
            "disabled": _vm.isDisabled
          }
        }, _vm.isPreview ? {} : _vm.isMediaRecorderSupported ? on : {
          click: function () {
            return _vm.$emit('prompt-upload');
          }
        }), [_c('v-icon', {
          domProps: {
            "innerHTML": _vm._s(_vm.iconPathAddVideoVintage)
          }
        })], 1)];
      }
    }], null, false, 2448001793)
  }, [_c('v-list', {
    staticClass: "py-0"
  }, [_c('v-list-item', {
    on: {
      "click": function ($event) {
        return _vm.$emit('prompt-record-video');
      }
    }
  }, [_c('v-list-item-icon', {
    staticClass: "mr-4"
  }, [_c('v-icon', {
    domProps: {
      "innerHTML": _vm._s(_vm.mdiRecord)
    }
  })], 1), _c('v-list-item-title', [_vm._v("Record…")])], 1), _c('v-list-item', {
    on: {
      "click": function ($event) {
        return _vm.$emit('prompt-upload');
      }
    }
  }, [_c('v-list-item-icon', {
    staticClass: "mr-4"
  }, [_c('v-icon', {
    domProps: {
      "innerHTML": _vm._s(_vm.mdiUpload)
    }
  })], 1), _c('v-list-item-title', [_vm._v("Upload file…")])], 1)], 1)], 1) : _c('v-btn', {
    attrs: {
      "fab": "",
      "color": "primary",
      "disabled": _vm.isDisabled
    },
    on: {
      "click": function ($event) {
        !_vm.isPreview && _vm.$emit('prompt-upload');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "large": ""
    },
    domProps: {
      "innerHTML": _vm._s(_vm.mdiPlus)
    }
  })], 1)], 1) : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }