<template>
  <div
    class="text-body-2 primary mt-3 mx-3 pa-3 v-card v-card--link v-sheet theme--dark elevation-20 relative"
    style="min-width: 200px; max-width: 300px;"
    v-bind:style="{ 'pointer-events': persistent ? 'none' : 'auto' }"
    @click="$emit('input', false)"
  >
    <slot></slot>
    <v-btn
      v-if="!persistent"
      class="primary elevation-6"
      style="top: -8px; right: -8px;"
      dark icon absolute x-small
      @click="$emit('input', false)"
    >
      <v-icon x-small v-html="mdiClose" />
    </v-btn>
  </div>
</template>

<script>
import { mdiClose } from '@mdi/js';

export default {
  name: 'snackbar',

  props: {
    value: Boolean,

    // Note that the value of 'persistent' should be static
    persistent: Boolean
  },

  created() {
    Object.assign(this, { mdiClose });
  },

  beforeDestroy() {
    this.$el.remove();
    clearTimeout(this.t);
  },

  watch: {
    value: {
      immediate: true,
      async handler(v) {
        await this.$nextTick();
        if (v) {
          document.getElementById('snackbar-container').appendChild(this.$el);
          if (!this.persistent)
            this.t = setTimeout(() => this.$emit('input', false), 4000);
        } else {
          this.$el.remove();
          clearTimeout(this.t);
        }
      }
    }
  }
}
</script>
