<template>
  <v-menu v-bind="$attrs" v-on="$listeners" :value="value" @input="onInput">
    <template v-slot:default="{ attrs, on }">
      <slot v-bind="{ attrs, on }"></slot>
    </template>
    <template v-slot:activator="{ attrs, on }">
      <slot name="activator" v-bind="{ attrs, on }"></slot>
    </template>
  </v-menu>
</template>

<script>
export default {
  name: 'menu-scroll-lock',

  props: [ 'value' ],

  created() {
    this.$set(this.global.scrollLock, this._uid, Boolean(this.value));
  },

  destroyed() {
    this.$delete(this.global.scrollLock, this._uid);
  },

  methods: {
    onInput(v) {
      this.$set(this.global.scrollLock, this._uid, v);
      this.$emit('input', v);
    }
  }
}
</script>
