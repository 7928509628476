<template>
  <dialog-scroll-lock v-if="global.resolveReauth" width="500" persistent :value="true">
    <v-card>
      <v-card-title>Credentials expired</v-card-title>
      <v-card-subtitle>Please sign in again</v-card-subtitle>
      <v-divider />
      <v-card-text class="pt-4">
        <sign-in-email :subscribe="false" @sign-in="global.resolveReauth" />
        <sign-in-btn-google class="mt-6" :subscribe="false" @sign-in="global.resolveReauth" />
        <sign-in-btn-facebook class="mt-6" :subscribe="false" @sign-in="global.resolveReauth" />
      </v-card-text>
      <v-divider />
      <v-card-text class="mt-4 text-caption">
        We do <b>not</b> use any personal details from third-party services. We only offer them as a convenience to verify your email address<span v-if="!IS_IFRAME"> &mdash; <a target="_blank" href="/privacy-policy">privacy policy</a></span>.
      </v-card-text>
    </v-card>
  </dialog-scroll-lock>
</template>


<script>
import SignInBtnFacebook from '@/components/SignInBtnFacebook.vue';
import SignInBtnGoogle from '@/components/SignInBtnGoogle.vue';
import SignInEmail from '@/components/SignInEmail.vue';


export default {
  name: 'sign-in-dialog-reauth',

  components: {
    SignInBtnFacebook,
    SignInBtnGoogle,
    SignInEmail
  }
}
</script>
