import store from '@/utils/local-storage.js';

// This is a simple version of vuex - rather than trying to manage an entire global state with getters / setters for
// everything, we have a simple 'global' mixin, and simply set properties as needed at this top level. It is important
// that this dictionary is a singleton as opposed to being defined in the mixin's 'data' method.
if (!window.GLOBAL)
  window.GLOBAL = {
    jwt: null,
    profile: null,
    order: null,
    didClosePromoCard: false,
    localBlobSrcMap: {},
    cookies: { strict: true, targeting: true, performance: true },
    videoDeviceId: null,
    audioDeviceId: null,
    referralCode: null,
    notify: null,
    localCategories: [],
    showThemePresets: true,
    showHelp: true,
    dark: false,
    didDisableSafeSearch: false,
    lastSelectedType: null,
    proxyTenor: false,
    scrollLock: {},
    resolveReauth: null,

    // Set this flag to exclude gift cards
    xgc: window.XGC
  };

if (store.isAvailable()) {
  // These values will be synchronized with localStorage if possible (logic is in the watchers in 'App')
  store.PERSIST = [
    'jwt',
    'videoDeviceId',
    'audioDeviceId',
    'referralCode',
    'showThemePresets',
    'showHelp',
    'didDisableSafeSearch'
  ];

  for (const key of store.PERSIST)
    if (store.has(key))
      window.GLOBAL[key] = store.get(key);

  // The 'storage' event should only be triggered by other browser windows / tabs
  addEventListener(
    'storage',
    e => {
      const key = e.key.match(/^ellacard\.(?<key>.+)$/)?.groups.key;
      if (store.PERSIST.includes(key))
        window.GLOBAL[key] = store.get(key);
    }
  );
}


// TODO: experiment with this
// function setPersistent() {
//   for (const key of store.PERSIST)
//     store.set(key, GLOBAL[key]);
// }
// addEventListener('beforeunload', setPersistent);

export default {
  data() {
    return { global: window.GLOBAL };
  },

  computed: {
    globalUserId() {
      return this.global.profile ? this.global.profile.user_id : null;
    },

    isSignedIn() {
      return !!this.global.profile && !this.global.profile.anon;
    }
  }
};
