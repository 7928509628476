var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-navigation-drawer', _vm._g(_vm._b({
    attrs: {
      "value": _vm.value
    },
    on: {
      "input": _vm.onInput
    }
  }, 'v-navigation-drawer', _vm.$attrs, false), _vm.$listeners), [_vm._t("default")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }