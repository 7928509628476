import vuetify from '@/plugins/vuetify';
import Vue from 'vue';
import SignInDialogReauth from '@/components/SignInDialogReauth.vue';
import { opDevFetch, OPError } from '@/utils/op-dev-fetch';


const rootURL = process.env.OP_DEV_CONFIG['service-url'].replace(/\/$/, '');

// For throttling requests
// const active = { t: null, n: 0 };

let refreshJWTPromise = null;

export default {
  methods: {
    async $refreshJWT() {
      if (refreshJWTPromise) {
        await refreshJWTPromise;
        return;
      }
      refreshJWTPromise = this._refreshJWTKernel();
      return await refreshJWTPromise;
    },

    async _promptReauth() {
      console.log('_promptReauth...');
      try {
        const jwt = await new Promise(r => this.global.resolveReauth = r);
        console.log('Got a JWT, removing the resolveReauth dialog...');
        this.global.resolveReauth = null;
        return jwt;
      } catch (e) {
        console.error(e);
      }
    },

    async _refreshJWTKernel() {
      const params = { method: 'REFRESH', payload: this.global.jwt };
      try {
        this.global.jwt = await opDevFetch(rootURL + '/get-jwt', undefined, params);
      } catch (e) {
        if (e.type == 'ForceReauth')
          this.global.jwt = await this._promptReauth();
        else if (e.type == 'AuthenticationException')
          this.global.jwt = null;
        throw e;
      } finally {
        refreshJWTPromise = null;
      }
    },

    async $call(fn, data=undefined) {
      const now = new Date();

      // Throttle requests
      // if (!active.t || (now - active.t) > 10000) {
      //   // The last request was made over 10 seconds ago, so we're good to go
      //   active.t = now;
      //   active.n = 0;
      // }
      // active.n++;
      // if (active.n >= 15) {
      //   // We've made at least 15 calls in the last ten seconds, slow down
      //   const delay = 10000 + (active.t - now);
      //   console.warn('Call-rate exceeded, throttling...');
      //   await new Promise(r => setTimeout(r, delay));
      //   return await this.$call(fn, data);
      // }

      if (this.global.jwt && !this.global.profile)
        // This can happen if a call is made immediately
        await this.$nextTick();

      if (this.global.profile && this.global.profile.exp < (now.getTime() / 1000) + 10)
        // Our token is expired (or will expire within the next 10 seconds)
        try {
          await this.$refreshJWT();
        } catch (e) {}

      const url = rootURL + '/' + fn.replace(/^\//, '');
      try {
        return await opDevFetch(url, this.global.jwt, data);
      } catch (e) {
        if (e instanceof OPError && e.type == 'ExpiredToken') {
          // Presumably, this shouldn't happen since we pre-emptively check for expired tokens
          try {
            await this.$refreshJWT();
          } catch (e2) {
            throw e;
          }
          return await opDevFetch(url, this.global.jwt, data);
        }
        if (!(e instanceof OPError) && !navigator.onLine)
          throw new OfflineError();
        throw e;
      }
    }
  }
};
