// Force the site to reload (upgrade to the latest version) if the user has been inactive for too long
window.lastActivity = new Date();
window.addEventListener('focus', () => (new Date() - window.lastActivity > 24 * 60 * 60 * 1000) && location.reload(true));
window.addEventListener('blur', () => window.lastActivity = new Date());

// Set up Google analytics with the 'gtag' function
window.dataLayer = window.dataLayer || [];
window.gtag = function() {
  dataLayer.push(arguments);
}

// Set up Microsoft analytics
window.uetq = window.uetq || [];

// Consent enabled by default to restore functionality for Google analytics (see also the 'global.cookies' watcher in
// App.vue)
// gtag('consent', 'default', { 'ad_storage': 'denied', 'analytics_storage': 'denied' });

gtag('js', new Date());
gtag('config', process.env.OP_DEV_CONFIG['google-analytics-id'], { send_page_view: false });
gtag('config', process.env.OP_DEV_CONFIG['google-ads-id'], { allow_enhanced_conversions: true });


// Globals
import 'lodash';

// Extend the JS Array object to add some useful functions / properties
// Object.defineProperties(
//   Array.prototype,
//   {
//     // The 'add' and 'remove' functions let us use arrays like a poor man's set (sets themselves don't have reactivity
//     // in Vue and this is fine for small numbers)
//     add(x) {
//       if (!this.includes(x))
//         this.push(x);
//     },
//     remove(x) {
//       const i = this.indexOf(x);
//       if (i != -1)
//         this.splice(i, 1);
//     },
//     last: {
//       get() {
//         return this[this.length - 1];
//       }
//     }
//   }
// );

// Note that we're careful to use Object.defineProperty instead of simply setting properties directly on the prototype
// to make sure they're not enumerable
Object.defineProperty(Array.prototype, 'add', {
  value(x) {
    if (!this.includes(x))
      this.push(x);
    return this;
  },
  enumerable: false
});
Object.defineProperty(Array.prototype, 'remove', {
  value(x) {
    const i = this.indexOf(x);
    if (i != -1)
      this.splice(i, 1);
    return this;
  },
  enumerable: false
});

Object.defineProperty(Array.prototype, 'last', { get() { return this[this.length - 1]; }, enumerable: false });
Object.defineProperty(Array.prototype, 'sum', { get() { return this.reduce((a, b) => a + b, 0); }, enumerable: false });

// Min / max accessors that work on non-numeric values. Behavior is unsupported if the array contains null or undefined
// values.
Object.defineProperty(Array.prototype, 'min', { get() { return this.reduce((a, b) => (a < b) ? a : b, undefined); }, enumerable: false });
Object.defineProperty(Array.prototype, 'max', { get() { return this.reduce((a, b) => (a > b) ? a : b, undefined); }, enumerable: false });

class VisibleError extends Error {
  constructor(msg, type='warning', showContactUs=false) {
    // 'type' should be one of 'error' or 'warning'
    super(msg);
    this.type = type;
    this.showContactUs = showContactUs;
  }
}
window.VisibleError = VisibleError;

class OfflineError extends Error {}
window.OfflineError = OfflineError;

// WARNING: you ran into issues were these properties were not immediately available everywhere, such as the
// /utils/local-storage.js file when checking the former IS_IFRAME property
window.IS_MOBILE = /Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
window.IS_IOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
window.IS_IOS_SAFARI = IS_IOS && !navigator.userAgent.match(/CriOS/i) && !navigator.userAgent.match(/OPiOS/i);
window.IS_IFRAME = window !== window.parent;

// Set this flag to exclude gift cards from the site (save for the button in the card-editor)
window.XGC = false;

// TODO: implement some of the stuff from here: https://v2.vuejs.org/v2/guide/components-dynamic-async.html

import Vue from 'vue';
import router from '@/router';
import App from '@/App.vue';
import vuetify from '@/plugins/vuetify';
import DialogScrollLock from '@/components/DialogScrollLock.vue';
import ErrorDialog from '@/components/ErrorDialog.vue';
import Snackbar from '@/components/Snackbar.vue';
import TooltipBtn from '@/components/TooltipBtn.vue';
import intersectInOut from '@/directives/intersect-in-out.js';
import category from '@/filters/category.js';
import countryName from '@/filters/country-name.js';
import moment from '@/filters/moment.js';
import email from '@/filters/email.js';
import emailPhone from '@/filters/email-phone.js';
import phone from '@/filters/phone.js';
import pl from '@/filters/pl.js';
import videoTimestamp from '@/filters/video-timestamp.js';
import usd from '@/filters/usd.js';
import asyncStatus from '@/mixins/async-status.js';
import confirm from '@/mixins/confirm.js';
import copyToClipboard from '@/mixins/copy-to-clipboard.js';
import globalStorage from '@/mixins/global-storage.js';
import notify from '@/mixins/notify.js';
import opDevCall from '@/mixins/op-dev-call.js';
import routeAssign from '@/mixins/route-assign.js';
import '@/patches/draw-image.js';
import EventTracker from '@/utils/event-tracker.js';
import loadScript from '@/utils/load-script.js';
import '@/styles/app.scss';

Vue.component('DialogScrollLock', DialogScrollLock);
Vue.component('ErrorDialog', ErrorDialog);
Vue.component('Snackbar', Snackbar);
Vue.component('TooltipBtn', TooltipBtn);

Vue.directive('intersect-in-out', intersectInOut);

Vue.filter('category', category);
Vue.filter('country-name', countryName);
Vue.filter('moment', moment);
Vue.filter('email', email);
Vue.filter('phone', phone);
Vue.filter('pl', pl);
Vue.filter('email-phone', emailPhone);
Vue.filter('video-timestamp', videoTimestamp);
Vue.filter('usd', usd);

Vue.mixin(asyncStatus);
Vue.mixin(confirm);
Vue.mixin(copyToClipboard);
Vue.mixin(notify);
Vue.mixin(globalStorage);
Vue.mixin(opDevCall);
Vue.mixin(routeAssign);

// Global properties

// A better way to measure this: https://stackoverflow.com/a/52854585
Vue.prototype.IS_TOUCH = ('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0);

Vue.prototype.IS_MOBILE = IS_MOBILE;
Vue.prototype.IS_IOS = IS_IOS;
Vue.prototype.IS_IOS_SAFARI = IS_IOS_SAFARI;
Vue.prototype.IS_IFRAME = IS_IFRAME;

Vue.config.errorHandler = (e, vm, info) => {
  // Captures errors that are specific to vue instances
  console.error(e);
  $event.log('err-vue', { component: vm.$options.name, info, stack: e?.stack || e });
};

addEventListener('error', e => {
  // console.error(e);
  $event.log('err-window', { stack: e.error?.stack || e.message });
  // e.stopImmediatePropagation();
  // e.preventDefault();
});

// All of the following external resources can be loaded asynchronously and should not block the initial render

window.loadRecaptchaPromise = new Promise(r => window.loadRecaptchaPromise_r = r);
window.loadStripePromise = new Promise(r => window.loadStripePromise_r = r);
window.loadPaypalPromise = new Promise(r => window.loadPaypalPromise_r = r);
window.loadDeferredScripts = () => {

  // https://developers.google.com/tag-platform/gtagjs/install
  loadScript(`https://www.googletagmanager.com/gtag/js?id=${process.env.OP_DEV_CONFIG['google-analytics-id']}`);

  // https://ui.ads.microsoft.com/campaign/vnext/uettag
  // loadScript('https://bat.bing.com/bat.js').then(() => {
  //   window.uetq = new UET({ ti: '56344998', q: uetq });
  //   uetq.push('pageLoad');
  // });

  loadScript('https://www.google.com/recaptcha/api.js').then(loadRecaptchaPromise_r);

  // Always load stripe globally to enable better fruad detection
  // https://www.npmjs.com/package/@stripe/stripe-js
  loadScript('https://js.stripe.com/v3').then(() => loadStripePromise_r(Stripe(process.env.OP_DEV_CONFIG['stripe-api-key'])));

  // Load PayPal once globally to save time later
  // https://developer.paypal.com/docs/checkout/reference/customize-sdk/#query-parameters
  loadScript(`https://www.paypal.com/sdk/js?client-id=${process.env.OP_DEV_CONFIG['paypal-client-id']}&currency=USD`).then(loadPaypalPromise_r);

};


const vm = new Vue({ vuetify, router, render: h => h(App) }).$mount('#app');

window.$event = new EventTracker(vm);
