<template>
  <!-- Note that it's necessary to prevent the other drag events in order for the drop event to work: https://stackoverflow.com/a/63066729/8439453 -->
  <div class="btn-container relative d-flex justify-center align-center" @drop.stop.prevent="onDrop">

    <template v-if="mode == 'purchase' || mode == 'edit'">

      <error-dialog :status="onDropStatus" />

      <snackbar persistent v-model="onDropStatus.pending">
        <v-progress-circular class="mr-2" indeterminate />
        <span>Processing file, please wait…</span>
      </snackbar>

      <div
        v-if="isDraggingOnPage && isPageActive && (selected.type == blob.type)"
        class="absolute-fill d-flex justify-center align-center drop-zone"
      >
        <v-btn
          style="transform-origin: center;"
          v-bind:style="{ transform: `scale(${1 / scale}) rotate(${-blob.mask.r}deg)` }"
          icon x-large color="white"
        >
          <v-icon x-large v-html="mdiUpload" />
        </v-btn>
      </div>
    </template>

    <div
      v-if="showCtrls"
      class="btn text-center"
      v-bind:class="{ 'is-hidden': isHidden }"
      v-bind:style="{ transform: `scale(${1 / scale}) rotate(${-blob.mask.r}deg)` }"
    >

      <v-menu v-if="blob.type == 'VIDEO'" offset-x right>
        <template v-slot:activator="{ on }">
          <v-btn
            v-blur
            fab color="primary"
            :disabled="isDisabled"
            v-on="isPreview ? {} : (isMediaRecorderSupported ? on : { click: () => $emit('prompt-upload') })"
          >
            <v-icon v-html="iconPathAddVideoVintage" />
          </v-btn>
        </template>
        <v-list class="py-0">
          <v-list-item @click="$emit('prompt-record-video')">
            <v-list-item-icon class="mr-4"><v-icon v-html="mdiRecord" /></v-list-item-icon>
            <v-list-item-title>Record…</v-list-item-title>
          </v-list-item>
          <v-list-item @click="$emit('prompt-upload')">
            <v-list-item-icon class="mr-4"><v-icon v-html="mdiUpload" /></v-list-item-icon>
            <v-list-item-title>Upload file…</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn v-else fab color="primary" :disabled="isDisabled" @click="!isPreview && $emit('prompt-upload')">
        <v-icon large v-html="mdiPlus" />
      </v-btn>
    </div>
  </div>
</template>

<style scoped>
.btn-container {
  width: 100%;
  height: 100%;
}

.drop-zone {
  background-color: rgba(33, 150, 243, 0.5);
  animation: drop-zone-reveal .3s ease-in-out forwards;
}
@keyframes drop-zone-reveal {
  0% { opacity: 0; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

.btn {
  transition: opacity .15s;
}
.btn.is-hidden {
  opacity: 0;
  pointer-events: none;
}
</style>

<script>
import { setTemplateBlob } from '@/utils/edit-order.js';
import { getImgDimensions, processImageFile, scale } from '@/utils/image.js';
import { getMetadata } from '@/utils/video.js';
import iconPathAddVideoVintage from 'raw-loader!@/assets/icon-path-add-video-vintage.txt';
import { mdiPlus, mdiRecord, mdiUpload } from '@mdi/js';


export default {
  name: 'mask-blob-controls',

  // Preconditions: the blob must be an IMG blob with a mask
  props: [ 'userId', 'blob', 'selected', 'mode', 'isPageActive', 'isDraggingOnPage', 'scale' ],

  data() {
    return {
      hover: false,
      onDropStatus: this.$track('onDrop')
    };
  },

  computed: {
    isPreview() {
      return this.mode == 'preview';
    },

    isMediaRecorderSupported() {
      return !!window.MediaRecorder && !IS_MOBILE;
    },

    opts() {
      return this.blob[ this.blob.type.toLowerCase() ];
    },

    showCtrls() {
      return (this.isPreview || (!this.selected || (this.selected === this.blob)) && !this.isDraggingOnPage) && (this.opts.src == this.blob.mask.placeholder);
    },

    isDisabled() {
      if (this.isPreview)
        return false;
      return !this.isPageActive || (this.mode == 'design') || (!this.isDraggingOnPage && this.opts.src != this.blob.mask.placeholder);
    },

    isHidden() {
      if (this.isPreview)
        return this.opts.src != '/template-placeholder.png';
      return !this.isPageActive;
    }
  },

  created() {
    Object.assign(this, { mdiPlus, mdiRecord, mdiUpload, iconPathAddVideoVintage });
  },

  methods: {
    async onDrop(e) {
      if (this.mode == 'design')
        return;

      const files = e.dataTransfer.files;
      if (files && files.length > 0) {
        const file = files[0];
        if (this.blob.type == 'IMG') {
          if (file.type.startsWith('image/')) {
            const { url, w, h } = await processImageFile(file);
            const m = this.blob.mask;
            const scaled = scale(w, h, m.w, m.h, 'cover');
            setTemplateBlob(this.userId, this.blob, url, scaled.w, scaled.h);
            return;
          }
          throw 'Can only drop images here';
        }
        if (this.blob.type == 'VIDEO') {
          if (file.type.startsWith('video/')) {
            const src = URL.createObjectURL(file);
            const { w, h } = await getMetadata(src);
            const m = this.blob.mask;
            const scaled = scale(w, h, m.w, m.h, 'cover');
            setTemplateBlob(this.userId, this.blob, src, scaled.w, scaled.h);
            return;
          }
          throw 'Can only drop videos here';
        }
      }

      const html = e.dataTransfer.getData('text/html');
      if (html) {
        const draggedDOM = (new DOMParser()).parseFromString(html, 'text/html');
        const imgs = draggedDOM.getElementsByTagName('img');
        if (imgs && imgs.length > 0) {
          const { w, h } = await getImgDimensions(imgs[0].src);
          setTemplateBlob(this.userId, this.blob, imgs[0].src, w, h);
          return;
        }
      }

      throw 'Can only drop images here';
    }
  }
}
</script>
