<template>
  <div>
    <error-dialog :status="signInStatus" />
    <v-btn
      class="btn-like-google-sign-in"
      elevation="0" outlined
      :loading="signInStatus.pending"
      @click="signIn"
    >
      <v-icon color="#1877F2" v-html="mdiFacebook" />
      <span>Continue with Facebook</span>
    </v-btn>
  </div>
</template>


<script>
import ErrorDialog from '@/components/ErrorDialog.vue';
import loadScript from '@/utils/load-script.js';
import { opDevFetch } from '@/utils/op-dev-fetch';
import { mdiFacebook } from '@mdi/js';

const FACEBOOK_APP_ID = process.env.OP_DEV_CONFIG['facebook-app-id'];
const rootURL = process.env.OP_DEV_CONFIG['service-url'].replace(/\/$/, '');

export default {
  name: 'sign-in-btn-facebook',

  components: {
    ErrorDialog
  },

  props: [ 'subscribe' ],

  data() {
    return {
      loadScriptPromise: null,
      signInStatus: this.$track('signIn')
    };
  },

  created() {
    Object.assign(this, { mdiFacebook });
  },

  async mounted() {
    this.loadScriptPromise = loadScript('https://connect.facebook.net/en_US/sdk.js');
  },

  methods: {
    async showFB() {
      FB.init({ appId: FACEBOOK_APP_ID, version: 'v7.0' });
      return await new Promise(resolve => FB.login(resolve, { scope: 'email' }));
    },

    async signIn() {
      await this.loadScriptPromise;
      const response = await this.showFB();
      if (!response.authResponse)
        return;
      let jwt;
      try {
        const params = { method: 'FACEBOOK', payload: response.authResponse.accessToken, subscribe: this.subscribe };
        jwt = await opDevFetch(rootURL + '/get-jwt', undefined, params);
      } catch (e) {
        if (e.type == 'NoFacebookEmail')
          await this.$confirm({
            title: 'No email address',
            msg: "We couldn't get your email address from Facebook. Please grant Ellacard access so you can sign in, or try a different method.",
            buttons: [ 'Ok' ],
            primary: 'Ok'
          });
        else
          throw e;
      }
      await new Promise(resolve => FB.logout(resolve));
      if (jwt)
        this.$emit('sign-in', jwt);
    }
  }
};
</script>
