<template>
  <dialog-scroll-lock width="400" :value="value" @input="reset">
    <v-alert :icon="mdiAlert" :type="display.type" class="ma-0 break-word">
      <v-btn class="float-right mt-n2 mr-n2 ml-3 mb-3" icon @click="reset">
        <v-icon v-html="mdiClose" />
      </v-btn>
      <div v-if="isVisibleError" v-html="display.message" />
      <slot v-else>
        <div v-html="display.message" />
      </slot>
      <div v-if="display.showContactUs" class="mt-4 ml-n10 font-italic caption">Seeing recurrent issues? Let us know: <a href="mailto:support@ellacard.com" class="white--text">support@ellacard.com</a></div>
    </v-alert>
  </dialog-scroll-lock>
</template>

<script>
import { mdiAlert, mdiClose } from '@mdi/js';

export default {
  name: 'error-dialog',

  props: [ 'status' ],

  data() {
    return {
      value: false
    }
  },

  computed: {
    display() {
      const e = this.status.error;

      if (e && (e instanceof VisibleError))
        // Instances of 'VisibleError' will already have the 'message', 'type', and 'showContactUs' properties set
        return e;

      if (e && (e instanceof OfflineError))
        return {
          message: 'Your browser appears to be offline. Please reconnect and try again later.',
          type: 'warning'
        };

      return {
        message: 'Sorry, something went wrong. Please try again later.',
        type: 'error',
        showContactUs: true,
        stack: e?.stack || e
      };
    },

    isVisibleError() {
      return this.display instanceof VisibleError;
    }
  },

  created() {
    Object.assign(this, { mdiAlert, mdiClose });
  },

  methods: {
    reset() {
      this.value = false;
      // Give the dialog time to fade out before we reset the status to ensure our error message doesn't change
      setTimeout(this.status.reset, 1000);
    }
  },

  watch: {
    'status.rejected': {
      immediate: true,
      handler(rejected) {
        this.value = rejected;
      }
    },

    value: {
      immediate: true,
      handler(v) {
        if (v) {
          const { type, stack, message } = this.display;
          const payload = { component: this.$parent?.$options?.name, type, stack };
          if (type == 'warning')
            payload.msg = message;
          $event.log('err-visible', payload);
        }
      }
    }
  }
};
</script>
