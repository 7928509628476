import constraints from '@/card-geometry-constraints.js';
import loadScript from '@/utils/load-script.js';

const { W, H } = constraints.page;

const IMG_SIZE_LIMIT = 10485760; // 10 MB

const SUPPORTED_CONTENT_TYPES = [
  'image/apng',
  'image/bmp',
  'image/gif',
  'image/x-icon',
  'image/jpeg',
  'image/png',
  'image/svg+xml',
  'image/webp',
  'image/heic'
];

const CONVERT_CONTENT_TYPES = [
  'image/webp'
];

const NO_CANVAS_CONTENT_TYPES = [
  'image/apng',
  'image/gif',
  'image/svg+xml'
];

async function getImgDimensions(imgURL) {
  const img = new Image();
  return await new Promise((resolve, reject) => {
    img.onload = () => resolve({ img, w: img.width, h: img.height });
    img.onerror = reject;
    img.src = imgURL;
  });
}


function readAsText(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.readAsText(file);
  });
}


function scale(w, h, targetW, targetH, objectFit) {
  const wScale = w / targetW;
  const hScale = h / targetH;
  if (wScale > 1 || hScale > 1) {
    switch (objectFit) {
      case 'cover':
        return wScale > hScale ? { w: targetW, h: h / wScale } : { w: w / hScale, h: targetH };
      case 'contain':
        return wScale > hScale ? { w: w / hScale, h: targetH } : { w: targetW, h: h / wScale };
    }
  }
  return { w, h };
}


function drawImgAsPlaceholder(ctx, img, w, h, targetW, targetH) {
  ctx.fillStyle = '#FFF';
  ctx.fillRect(0, 0, w, h);
  ctx.globalAlpha = 0.5;
  const scale = img.width / w;
  ctx.drawImage(img, scale * (w - targetW) / 2, scale * (h - targetH) / 2, scale * targetW, scale * targetH, 0, 0, w, h);

  // Note that you have to use 'w' and 'h' here instead of 'targetW' and 'targetH' - I'm not sure I fully understand
  // the API...
  const imgData = ctx.getImageData(0, 0, w, h);

  let px;
  for (let i = 0; i < imgData.data.length; i += 4) {
    // Adjust the weights for different desaturation effects (make sure they sum up to 1)
    px = 0.2125 * imgData.data[i] + 0.7154 * imgData.data[i + 1] + 0.0721 * imgData.data[i + 2];
    imgData.data[i] = px;
    imgData.data[i + 1] = px;
    imgData.data[i + 2] = px;
  }
  ctx.putImageData(imgData, 0, 0);
}


async function processImageFile(rawFile, targetW=W, targetH=H, placeholder=false) {
  if (!SUPPORTED_CONTENT_TYPES.includes(rawFile.type))
    throw new VisibleError('Unsupported image type');
  if (rawFile.type == 'image/svg+xml') {
    // Make sure that SVGs don't contain any foreign fonts
    const content = await readAsText(rawFile);
    if (content.match(/font-family/g))
      throw new VisibleError(`Cannot upload SVG files with custom fonts, as they won't display uniformly on all browsers. If you are the creator of this file, try converting the text to outlines (your SVG editor likely has an "convert to web" option).`);
  }
  let isAnimated = false;
  if (rawFile.type == 'image/gif') {
    // Note that this could actually be a static GIF, but the consequences of treating all GIFs as animated are
    // minimal. You may consider using a library like this:
    // https://www.npmjs.com/package/animated-gif-detector
    isAnimated = true;
  } else if (rawFile.type == 'image/png') {
    // Animated PNGs look like regular PNGs, so they require special handling
    // https://stackoverflow.com/questions/4525152/can-i-programmatically-determine-if-a-png-is-animated
    const content = await readAsText(rawFile);
    const i = content.substring(1, content.indexOf('IDAT')).indexOf('acTL');
    isAnimated = i != -1;
  } else if (rawFile.type == 'image/webp') {
    // WEBP images could also be animated
    // https://stackoverflow.com/a/61242086/8439453
    const content = await readAsText(rawFile);
    isAnimated = content.indexOf('ANMF') != -1;
    if (isAnimated)
      throw new VisibleError('Animated WebP files are not yet supported');
  } else if (rawFile.type == 'image/heic') {
    // HEIC files can sometimes present as JPEG files. I don't fully understand how this works, but the conversion
    // here can fail and the browser will still be able to draw the image onto a canvas. There's actually a more
    // insidious case where the conversion can *appear* to work, but will corrupt the image. This seems to only be
    // reproducible in Safari when the file is uploaded using a specific input that accepts 'image/heic' (as opposed to
    // reading the file from a 'drop' event). You had to switch from the 'heic-2-any' library to 'heic-to' to get
    // around that. If the conversion outright fails, we might as well try to draw it onto the canvas as a JPEG since
    // it could actually succeed...
    const { heicTo } = await import(/* webpackIgnore: true */ '/lib/heic-to.min.js');
    try {
      rawFile = await heicTo({ blob: rawFile, type: 'image/jpeg', quality: 0.5 })
    } catch (e) {
      rawFile = new File([ new Blob([ rawFile ]) ], rawFile.name, { type: 'image/jpeg' });
    }
  }
  const objectURL = URL.createObjectURL(rawFile);
  const { img, w, h } = await getImgDimensions(objectURL);
  if (NO_CANVAS_CONTENT_TYPES.includes(rawFile.type) || isAnimated) {
    if (rawFile.size > IMG_SIZE_LIMIT)
      // Note that other images are compressed using the canvas, so we only need to limit the file size for these types
      throw new VisibleError('Image is too large (10 MB limit)');
    return { url: objectURL, w, h, isAnimated };
  }

  // Compress / convert the image based on the size of the canvas - note that masked images may grow beyond this size,
  // so they could be blurry if the user zooms really far in
  const scaled = scale(w, h, targetW * 2, targetH * 2, 'contain');
  const canvas = document.createElement('canvas');
  canvas.width = scaled.w;
  canvas.height = scaled.h;
  const ctx = canvas.getContext('2d');
  if (placeholder)
    drawImgAsPlaceholder(ctx, img, scaled.w, scaled.h, targetW * 2, targetH * 2);
  else
    ctx.drawImage(img, 0, 0, scaled.w, scaled.h);
  const canvasDataURL = canvas.toDataURL('image/' + (rawFile.type == 'image/png' ? 'png' : 'jpeg'));
  const blob = await fetch(canvasDataURL).then(r => r.blob());

  if (CONVERT_CONTENT_TYPES.includes(rawFile.type)) {
    if (rawFile.size > IMG_SIZE_LIMIT)
      // Note that we still check the 'rawFile' here - it's not fair to the user (and doesn't make sense) if they
      // upload a highly compressed WEBP image at 9.9 MB, and turning it into a worse JPEG bumps that over 10 MB (but
      // with more compatibility). This does hover mean that the resulting blob could technically be over the size
      // limit.
      throw new VisibleError('Image is too large (10 MB limit)');
    return { url: URL.createObjectURL(blob), ...scaled, isAnimated };
  }

  if (rawFile.size < blob.size) {
    // The raw image is actually smaller than our "compressed" version, so just stick with that
    if (rawFile.size > IMG_SIZE_LIMIT)
      throw new VisibleError('Image is too large (10 MB limit)');
    return { url: objectURL, w, h, isAnimated };
  }

  if (blob.size > IMG_SIZE_LIMIT)
    // This should be very unlikely depending on how 'maxW' and 'maxH' have been set
    throw new VisibleError('Compressed image is too large (10 MB limit)');
  return { url: URL.createObjectURL(blob), ...scaled, isAnimated };
}

async function isAnimated(src) {
  const response = await fetch(src);
  switch (response.headers.get('Content-Type')) {
    case 'image/gif':
      return true;
    case 'image/png':
      const content = await response.text();
      const i = content.substring(1, content.indexOf('IDAT')).indexOf('acTL');
      return i != -1;
    case 'image/webp':
      return (await response.text()).indexOf('ANMF') != -1;
    case 'video/mp4':
      return true;
    default:
      return false;
  }
}

export { getImgDimensions, processImageFile, scale, isAnimated };
