var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-menu', _vm._g(_vm._b({
    attrs: {
      "value": _vm.value
    },
    on: {
      "input": _vm.onInput
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("default", null, null, {
          attrs,
          on
        })];
      }
    }, {
      key: "activator",
      fn: function (_ref2) {
        var attrs = _ref2.attrs,
          on = _ref2.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true)
  }, 'v-menu', _vm.$attrs, false), _vm.$listeners));

}
var staticRenderFns = []

export { render, staticRenderFns }