var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.global.resolveReauth ? _c('dialog-scroll-lock', {
    attrs: {
      "width": "500",
      "persistent": "",
      "value": true
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v("Credentials expired")]), _c('v-card-subtitle', [_vm._v("Please sign in again")]), _c('v-divider'), _c('v-card-text', {
    staticClass: "pt-4"
  }, [_c('sign-in-email', {
    attrs: {
      "subscribe": false
    },
    on: {
      "sign-in": _vm.global.resolveReauth
    }
  }), _c('sign-in-btn-google', {
    staticClass: "mt-6",
    attrs: {
      "subscribe": false
    },
    on: {
      "sign-in": _vm.global.resolveReauth
    }
  }), _c('sign-in-btn-facebook', {
    staticClass: "mt-6",
    attrs: {
      "subscribe": false
    },
    on: {
      "sign-in": _vm.global.resolveReauth
    }
  })], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "mt-4 text-caption"
  }, [_vm._v(" We do "), _c('b', [_vm._v("not")]), _vm._v(" use any personal details from third-party services. We only offer them as a convenience to verify your email address"), !_vm.IS_IFRAME ? _c('span', [_vm._v(" — "), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "/privacy-policy"
    }
  }, [_vm._v("privacy policy")])]) : _vm._e(), _vm._v(". ")])], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }