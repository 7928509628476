<template>
  <!-- Note that we use 'opacity' instead of v-show to ensure there are no hiccups in the font rendering process. -->
  <div
    class="text-blob mx-n4 my-n2 px-4 py-2 d-flex"
    :class="`align-${blob.text.valign || 'center'}`"
    v-bind:style="{ opacity: (loadFontStatus.pending || !didAutoScale) ? 0 : 1 }"
  >

    <!-- Note that we add a small margin to make sure the text doesn't butt right up against the border -->
    <div v-if="showTemplateEditable" class="absolute text-blob-fill template-editable" />

    <div
      ref="text"
      class="text-input renderable-text"
      v-bind:class="{ 'user-select-auto': isSelected || mode == 'view' }"
      v-bind:style="blob.text.css || {}"
      spellcheck="false"
      :contenteditable="isEditable && (!blob.text.event_link || blob.text.event_link.disabled)"
      @input="onInput"
      @focus="onFocusText"
      @blur="onBlurText"
      @paste="onPasteText"
      @keydown.stop="onKeyDown"
      @dragstart.stop.prevent
    />
  </div>
</template>

<style scoped>
.text-blob {
  width: calc(100% + 32px);
  height: calc(100% + 16px);
  transition: opacity .15s;
}
.text-input {
  line-height: 1.5;
  outline: none;
  line-break: normal;
  white-space: pre-wrap;
}
.text-blob-fill {
  margin: -4px;
  width: calc(100% + 2 * 4px);
  height: calc(100% + 2 * 4px);
}
.template-editable {
  box-sizing: border-box;
  border: 1px dashed rgb(25, 118, 210);
  transition: background-color .2s;
}
.template-editable:hover {
  background-color: rgba(25, 118, 210, .08);
}
</style>

<style>
sup {
  position: inherit;
  vertical-align: super;
}

.text-blob a {
  color: inherit !important;
  pointer-events: auto;
}

@keyframes font-effect-fire {
  0% {
    text-shadow: 0 -0.05em 0.2em #FFF, 0.01em -0.02em 0.15em #FE0, 0.01em -0.05em 0.15em #FC0, 0.02em -0.15em 0.2em #F90, 0.04em -0.20em 0.3em #F70,0.05em -0.25em 0.4em #F70, 0.06em -0.2em 0.9em #F50, 0.1em -0.1em 1.0em #F40;
  }
  25% {
    text-shadow: 0 -0.05em 0.2em #FFF, 0 -0.05em 0.17em #FE0, 0.04em -0.12em 0.22em #FC0, 0.04em -0.13em 0.27em #F90, 0.05em -0.23em 0.33em #F70, 0.07em -0.28em 0.47em #F70, 0.1em -0.3em 0.8em #F50, 0.1em -0.3em 0.9em #F40;
  }
  50% {    text-shadow: 0 -0.05em 0.2em #FFF, 0.01em -0.02em 0.15em #FE0, 0.01em -0.05em 0.15em #FC0, 0.02em -0.15em 0.2em #F90, 0.04em -0.20em 0.3em #F70,0.05em -0.25em 0.4em #F70, 0.06em -0.2em 0.9em #F50, 0.1em -0.1em 1.0em #F40;
  }
  75% {
    text-shadow: 0 -0.05em 0.2em #FFF, 0 -0.06em 0.18em #FE0, 0.05em -0.15em 0.23em #FC0, 0.05em -0.15em 0.3em #F90, 0.07em -0.25em 0.4em #F70, 0.09em -0.3em 0.5em #F70, 0.1em -0.3em 0.9em #F50, 0.1em -0.3em 1.0em #F40;
  }
  100% {
    text-shadow: 0 -0.05em 0.2em #FFF, 0.01em -0.02em 0.15em #FE0, 0.01em -0.05em 0.15em #FC0, 0.02em -0.15em 0.2em #F90, 0.04em -0.20em 0.3em #F70,0.05em -0.25em 0.4em #F70, 0.06em -0.2em 0.9em #F50, 0.1em -0.1em 1.0em #F40;
  }
}

@keyframes font-effect-stripes {
  from {
    background-position: 0px;
  }
  to {
    background-position: 1000px;
  }
}

@keyframes font-effect-shimmer {
  to {
    background-position-x: 100%;
  }
}

/* Ok, we have a problem. Text rendering varies by browser. We're using specific fonts, but we generally don't import a separate BOLD version of any of these fonts, leaving browsers to apply their OWN bold styling. In Chrome, bold styling does not change the width of the text, but in Safari and Firefox, it does. This can causes wrapping issues.
There are two possible solutions:
- Import specific versions of every font for regular, bold, regular italic, and bold italic (although note that regular italic doesn't seem to have the same problem)
- Create our own pseudo-bold styling using text-stroke. The problem with this is that it prevents the browser from recognizing that the text is bolded, which means that you cannot un-bold text. Since this is an obscure option not readily presented in the interface, and you can easily work around that by deleting the blob and recreating it, we're going to go with that.
*/
/*
.text-input b,
.text-input strong {
  color: inherit;
  font-weight: normal;
  text-stroke: 2px currentColor;
  -webkit-text-stroke: 2px currentColor;
}
*/
</style>

<script>
import constraints from '@/card-geometry-constraints.js';
import { hasContent, shouldSaveBlob } from '@/utils/edit-order.js';
import { EMAIL_REGEX } from '@/utils/email.js';
import loadFont from '@/utils/load-font.js';
import { resizeTextEl } from '@/utils/render-text.js';

const { W, H } = constraints.page;
const { MIN_H, MAX_W, MAX_H } = constraints.blob.TEXT;

/*
TODO: figure out a cross-platform way to test if the keyboard is visible - listening for focus and checking the activeElement is NOT a reliable way to do it, i.e. the use case where a text blob exists but is not selected, then a user double-taps it. Note that that particular bug may have something to do with the second tap getting processed as a mouse event instead of a touch event...

TODO: you should also use this function in place of the 'virtual-keyboard' event and 'virtualKeyboard' variable that you currently use in the card-editor.

It seems like people mostly go the route of watching for screen resize events, though they're handled differently on Android vs iOS. From a Stack Overflow comment:
Android Chrome shrinks the viewport height to make room for the keyboard, so the page resizes when the keyboard is shown. iOS Safari overlays the page with the keyboard (page size stays the same), and changes how scrolling works. Safari both scrolls the page inside the viewport, and concurrently moves the viewport, ensuring that the bottom of the page is above the keyboard when scrolled all the way down.

https://stackoverflow.com/questions/28272274/how-to-detect-keyboard-show-hide-event-in-jquery-for-mobile-web-application/28272408

https://stackoverflow.com/questions/2593139/ipad-web-app-detect-virtual-keyboard-using-javascript-in-safari/19464062
*/

const LINK_REGEX = new RegExp(/(((ht|f)tps?:\/\/)|(\w[-+.%'~\w]*@))?([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])/g);
const LINK_TLDS = new Set([ 'com', 'net', 'edu', 'org', 'gov', 'info', 'xyz', 'site', 'me', 'co', 'us', 'uk', 'au', 'nz', 'eu', 'io', 'ai' ]);


// You had noticed in the past that Safari applies different properties based on whether or not the 'contenteditable'
// property is set. You had added the '-webkit-nbsp-mode: space;' setting in the scoped 'text-input' CSS class to
// account for that, but that of course means that &nbsp; characters wouldn't work as intended. I don't remember what
// behavior had caused you to do that in the first place, but if you find that you do need to set that property again,
// then you will need this function to actually replace &nbsp; characters with a span that has the same effect.
// function replaceNBSP(v) {
//   return (v || '').replace(/&nbsp;/g, '<span style="white-space: nowrap;"> </span>');
// }

export default {
  name: 'text-blob',

  props: [ 'blob', 'mode', 'isEditable', 'isSelected', 'isTransformInProgress' ],

  data() {
    return {
      didAutoScale: false,
      loadFontStatus: this.$track('loadFont'),
      autoScaleTextStatus: this.$track('autoScaleText')
    };
  },

  computed: {
    isFitToPage() {
      const b = this.blob;
      return b.x == W / 2 && b.w == MAX_W && b.y == H / 2 && b.h == MAX_H;
    },

    needsAutoScale() {
      const b = this.blob;
      // Note that we include color since it happens to be set by the 'resizeTextEl' function
      return [ b.text.text, b.text.font, b.text.color, b.text.size, b.text.align, b.text.valign, b.text.css, b.w, b.h ];
    },

    showTemplateEditable() {
      // You also experimented with limiting this in a few other cases as well, for example adding the clause:
      // (!this.blob.user_id || !hasContent(this.blob.text.text))
      return this.blob.template && this.isEditable && !this.isSelected && (!this.blob.user_id || !hasContent(this.blob.text.text));
    },

    selectTextOnFocus() {
      if (IS_MOBILE)
        return false;
      if ((!this.blob.user_id && (this.mode != 'design')) || (this.blob.text.text == 'Message here'))
        return true;
      return false;
    }
  },

  created() {
    Object.assign(this, { MAX_H });
  },

  methods: {
    async loadFont() {
      await loadFont(this.blob.text.font);
      await this.$nextTick();
    },

    async focus(select) {
      if (!this.$refs.text)
        return;
      await this.loadFontStatus.promise;
      this.$refs.text.focus();
      if (this.blob.text.text)
        setTimeout(
          () => {
            if (this.selectTextOnFocus || select)
              getSelection().selectAllChildren(this.$refs.text);
            // else
            //   getSelection().collapse(this.$refs.text.lastChild, this.$refs.text.innerText.length);
          }
        );
    },

    onInput() {
      // For block-level contenteditable elements, browsers will insert a newline character into the innerText instead
      // of leaving it empty
      // https://stackoverflow.com/questions/14638887/br-is-inserted-into-contenteditable-html-element-if-left-empty
      const el = this.$refs.text;
      this.blob.text.text = (el.innerText == '\n') ? '' : el.innerHTML;
      this.autoScaleText();
    },

    clearDefaultText() {
      // User started typing without clearing the placeholder text (let's clear it for them)
      if (this.blob.text.text == 'Message here') {
        // Note the distinction between 'innerText' and 'innerHTML', the former will strip any invisible tags like
        // '<b>' or '<i>''
        this.$refs.text.innerHTML = '';
        this.onInput();
      }
    },

    onKeyDown(e) {
      if (e.keyCode == 27) {
        this.$refs.text.blur();
        this.$emit('deselect');
      } else {
        this.clearDefaultText();
      }
    },

    onFocusText() {
      if (this.selectTextOnFocus)
        setTimeout(() => getSelection().selectAllChildren(this.$refs.text));

      // Insane but effective trick to determine if a virtual keyboard is visible in iOS (both Chrome and Safari).
      // For Android devices, the window explicitly resizes, which means the normal scroll-handling mechanisms are
      // sufficient.
      // https://stackoverflow.com/a/2601085/8439453
      if (!IS_MOBILE)
        return;
      const el = document.documentElement;
      el.scrollTop = 10;
      if (el.scrollTop > 0)
        this.$emit('update:virtual-keyboard', true);
      el.scrollTop = 0;
    },

    onBlurText() {
      this.$emit('blur');
      this.$emit('update:virtual-keyboard', false);
      this.blob.text.text = this.$refs.text.innerHTML;
      getSelection().removeAllRanges();
    },

    onPasteText(e) {
      // We have a special handler here because we actually allow pasting in a text blob to capture things like
      // stickers / GIFs from mobile device keyboards.
      const plainText = e.clipboardData.getData('text/plain');
      if (plainText) {
        // Note that 'plainText' actually captures things like image URLs as well
        e.preventDefault();
        e.stopPropagation();

        this.clearDefaultText();

        // https://stackoverflow.com/questions/2920150/insert-text-at-cursor-in-a-content-editable-div
        const selection = getSelection();
        const range = selection.getRangeAt(0);
        range.deleteContents();
        range.insertNode(document.createTextNode(plainText));
        this.autoScaleText();
        this.onInput();
      }
    },

    fixScroll() {
      // A user can enter enough text to push the height of the inner element beyond the max height. Even though we
      // have overflow hidden, this still causes the element to scroll, so we fix that here. The consequence is a
      // little "flicker".
      setTimeout(() => this.$el.scrollTop = 0);
    },

    async autoScaleText() {
      await this.loadFont();
      await this.$nextTick();

      // There is an occasional problem where oversized text (text that has its initial font size set to larger than
      // can fit in its container) doesn't auto-scale correctly on first load in some instances. To reproduce, open a
      // card with such a condition, like this one: https://127.0.0.1:8080/cards/9aeca4bd-fa09-4872-ab75-37273c27607b // Then select a card type and click "Use this design" - when it opens to the card-sign page, the text won't
      // scale down correctly (sometimes) without this busy-wait loop. This is so fragile, and I don't even know what
      // causes it, hence the timeout to be extra cautious.
      // TODO: figure out a better solution than busy-waiting
      for (let i = 0; this.$refs.text && !this.$refs.text.scrollHeight && (i < 100); i += 10)
        await new Promise(r => setTimeout(r, 10));

      if (!this.$refs.text)
        return;

      const opts = {
        color: this.blob.text.color,
        font: this.blob.text.font,
        font_size: this.blob.text.size,
        min_font_size: constraints.blob.TEXT.MIN_SIZE,
        size: this.blob,
        justify: this.blob.text.align,
        align: this.blob.text.valign
      };
      resizeTextEl(this.$refs.text, opts, 0, true, !this.didAutoScale);

      // TODO: you might want to explore this option if you find performance suffering - not sure about relying on
      // 'requestAnimationFrame' to run to completion (and frankly I don't think it's an issue)
      // await new Promise(
      //   r => requestAnimationFrame(
      //     () => {
      //       resizeTextEl(this.$refs.text, opts, 0, true, !this.didAutoScale);
      //       r();
      //     }
      //   )
      // );

      // We need to keep track of the first time we auto-scaled text because it also applies the styles from the
      // 'opts' above, and we don't want to show the text at all without those styles.
      this.didAutoScale = true;
    },

    injectLinks() {
      if (this.isSelected || !this.$refs.text || this.blob.text.ignore_links)
        return;

      let match;
      let updated = '';
      let lastMatchEndI = 0;
      while (match = LINK_REGEX.exec(this.blob.text.text)) {
        updated += this.blob.text.text.substring(lastMatchEndI, match.index);
        if (match[0].startsWith('http')) {
          updated += `<a href="${match[0]}" target="_blank">${match[0]}</a>`;
        } else if (EMAIL_REGEX.test(match[0])) {
          updated += `<a href="mailto:${match[0]}" target="_blank">${match[0]}</a>`;
        } else {
          const tld = match[0].substring(match[0].lastIndexOf('.') + 1);
          if (LINK_TLDS.has(tld))
            updated += `<a href="http://${match[0]}" target="_blank">${match[0]}</a>`;
          else
            updated += match[0];
        }
        lastMatchEndI = match.index + match[0].length;
      }

      if (updated)
        this.$refs.text.innerHTML = updated + this.blob.text.text.substring(lastMatchEndI); // replaceNBSP
    }
  },

  watch: {
    isSelected: {
      immediate: true,
      async handler(v) {
        if (v) {
          // Make sure to remove any injected links
          setTimeout(() => {
            if (this.$refs.text)
              this.$refs.text.innerHTML = this.blob.text.text; // replaceNBSP
          });
        } else {
          this.fixScroll();
          if (shouldSaveBlob(this.blob))
            setTimeout(() => this.injectLinks());
          else
            this.$emit('delete');
        }
      }
    },

    needsAutoScale: {
      immediate: true,
      deep: true,
      async handler() {
        this.fixScroll();

        await this.$nextTick();
        if (!this.$refs.text)
          return;

        // We can't simply bind the 'blob.text.text' property to our contenteditable div using v-html, because
        // updates to the model cause undesired behavior, mainly resetting the cursor to the beginning. This SO
        // question is about the same issue, although they didn't have a good solution...
        // https://stackoverflow.com/questions/40666774/vue-js-v-html-contenteditable-prevent-dom-refresh-to-keep-cursor-caret-from-ju
        if (this.blob.text.text != this.$refs.text.innerHTML)
          this.$refs.text.innerHTML = this.blob.text.text; // replaceNBSP

        await this.autoScaleText();
        await this.$nextTick();

        if (!this.isLoaded) {
          // Make sure to wait for the opacity transition on the 'text-blob' class
          await new Promise(r => setTimeout(r, 150));

          this.isLoaded = true;
          this.$emit('loaded');
        }
      }
    }
  }
}
</script>
